/* jshint esversion: 11 */
/* jshint -W097 */
/* jshint -W098 */
/* jshint -W117 */
/* jshint -W069 */

import { FOJobStatus } from 'fo-gateway-sdk-ui';
import { ConsultationCalendar } from 'fo-consultation-calendar-ui';
import { FOJobStatusWidget } from 'fo-gateway-sdk-ui/src/status-widget';
import { WebsocketClient } from "fmc-websocket-client-ui";

document.addEventListener('DOMContentLoaded', () => {
    new FOJobStatus();
    new FOJobStatusWidget();
    new ConsultationCalendar();
    new WebsocketClient();
});
